<template>
  <div class="user-management-container">
    <el-button @click="goBack" style="margin-bottom: 20px">返回</el-button>
    <el-form :inline="true" :model="queryForm" @submit.prevent>
      <el-form-item>
        <el-input
          v-model.trim="queryForm.keyword"
          clearable
          placeholder="请输入名称"
        />
      </el-form-item>
      <!-- <el-form-item label="企业:">
            <el-select
              v-model="queryForm.enterpriseCode"
              @change="queryData"
              clearable
            >
              <template v-for="(item, index) in enterPriseList" :key="index">
                <el-option
                  :value="item.enterpriseCode"
                  :label="item.enterpriseName"
                ></el-option>
              </template>
            </el-select>
          </el-form-item> -->
      <el-form-item>
        <el-button :icon="Search" type="primary" @click="queryData">
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form
          :model="queryForm"
          @submit.prevent
          :label-position="labelPosition"
        >
          <el-form-item
            label="状态"
            style="margin: 0 0 10px 0 !important"
            label-width="100px"
          >
            <ul class="radioBox">
              <li
                @click="selectChange('userType', item.key)"
                :class="[queryForm.auditStatus == item.key ? 'isChecked' : '']"
                v-for="(item, index) in userTypeList"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </el-form-item>
          <el-form-item
            label="用户身份"
            style="margin: 0 0 10px 0 !important"
            label-width="100px"
          >
            <ul class="radioBox">
              <li
                @click="selectChange('officalCount', item.identity)"
                :class="[
                  queryForm.identity == item.identity ? 'isChecked' : '',
                ]"
                v-for="(item, index) in officalCountList"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </el-form-item>
          <el-form-item
            label="是否存在审核通过的会议活动"
            style="margin: 0 0 10px 0 !important"
            label-width="200px"
          >
            <ul class="radioBox">
              <li
                @click="
                  selectChange(
                    'isExistAuditPassForum',
                    !queryForm.isExistAuditPassForum
                  )
                "
                :class="[
                  queryForm.isExistAuditPassForum == true ? 'isChecked' : '',
                ]"
              >
                是
              </li>
              <!-- <li @click="selectChange('isExistAuditPassForum', false)" :class="[queryForm.isExistAuditPassForum ==  false? 'isChecked' : '']">否</li> -->
            </ul>
            <!-- <el-switch  @change="selectChangeBySwitch" v-model="queryForm.isExistAuditPassForum" size="large" active-text="是" inactive-text="否" /> -->
          </el-form-item>
          <el-form-item
            label="批量操作"
            style="margin: 0 0 10px 0 !important"
            label-width="100px"
            v-if="checkUserList.length > 0"
          >
            <template v-if="queryForm.auditStatus == 'PENDING_REVIEW'">
              <el-button type="primary" @click="accept">通过</el-button>
              <el-button type="danger" @click="dialogFormVisible1 = true">
                不通过
              </el-button>
            </template>

            <template v-if="queryForm.auditStatus == 'ACCEPT'">
              <el-button type="danger" @click="dialogFormVisible1 = true">
                不通过
              </el-button>
            </template>

            <template v-if="queryForm.auditStatus == 'REFUSE'">
              <el-button type="primary" @click="accept">通过</el-button>
            </template>

            <el-switch
              style="margin-left: 10px"
              v-model="isSendMsg"
              size="large"
              active-text="发送"
              inactive-text="不发送"
            />
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- -->
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="queryForm.auditStatus != 'CANCEL_SIGN'"
      />
      <el-table-column
        align="center"
        label="用户姓名"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="公司/机构" prop="submitTime">
        <template #default="{ row }">
          {{ row.organization ? row.organization : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="职位" prop="submitTime">
        <template #default="{ row }">
          {{ row.position ? row.position : '/' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="submitTime">
        <template #default="{ row }">
          {{ statusList[row.status] }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="提交时间" prop="submitTime">
        <template #default="{ row }">
          {{ handleDate(row.submitTime) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="primary">
            查看详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit
      ref="editRef"
      @fetch-data="fetchData"
      :meetingCode="queryForm.meetingCode"
    />
  </div>

  <el-drawer
    title="填写驳回原因"
    ref="drawerRef"
    v-model="dialogFormVisible1"
    :size="size1"
    :append-to-body="true"
  >
    <el-form :model="form" :rules="rules" ref="reasonRef">
      <el-form-item label="原因" prop="reason">
        <el-input v-model="form.reason" type="textarea" />
      </el-form-item>
      <el-form-item align="center">
        <el-button @click="refuse" type="primary" size="large">提交</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import {
  attendAuditList,
  getRegisterConfigList,
  attendAudit,
} from '@/api/exhibitionManage'
import { useRoute } from 'vue-router'
import { parseTime } from '@/utils/index'
export default defineComponent({
  name: 'UserManage',
  components: {
    Edit: defineAsyncComponent(() => import('./components/userDetail.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      labelPosition: `left`,
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      showShop: false,
      counrtyList: [],
      keywod: '',
      checkUserList: [],
      reason: '拒绝你',
      form: {
        reason: '',
      },
      dialogFormVisible1: false,
      size1: '30%',
      rules: {
        reason: [
          {
            required: true,
            message: '请填写驳回原因',
            trigger: 'blur',
          },
        ],
      },
      isSendMsg: true,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        usernameLike: '',
        identity: '',
        auditStatus: 'PENDING_REVIEW',
        keyword: '',
        meetingCode: '',
        meetingType: 'EXHIBITION',
        isExistAuditPassForum: false,
      },
      statusList: {
        PENDING_REVIEW: '待审核',
        ACCEPT: `审核通过`,
        REFUSE: '审核不通过',
        CANCEL_SIGN: `取消报名`,
        NOT_REVIEW: `无需审核`,
      },
      userTypeList: [
        // {
        //   name: '全部',
        //   key: '',
        // },
        {
          name: '待审核',
          key: 'PENDING_REVIEW',
        },
        {
          name: '审核通过',
          key: 'ACCEPT',
        },
        {
          name: '审核不通过',
          key: 'REFUSE',
        },
        {
          name: '取消报名',
          key: 'CANCEL_SIGN',
        },
      ],
      officalCountList: [],
      reasonRef: null,
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }
    const handleEdit = (row) => {
      state['editRef'].handleOpen(row)
    }
    const handleDelete = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteUser(row.uid)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }

    const route = useRoute()

    const queryData = () => {
      state.queryForm.page = 1
      fetchData()
    }
    const handleQueryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await attendAuditList(state.queryForm)
      state.listLoading = false
      if (data == null) {
        state.list = []
        state.total = 0
        return false
      }
      state.list = data.data
      state.total = data.total
    }
    onMounted(async () => {
      state.queryForm.meetingCode = route.query.meetingCode
      let { data: identityList } = await getRegisterConfigList(
        state.queryForm.meetingCode
      )
      console.log(identityList)
      state.officalCountList = identityList
      state.queryForm.identity =
        identityList.length > 0 ? identityList[0].identity : 'AUDIENCE'
      fetchData()
    })

    const selectChange = (type, key) => {
      if (type == 'userType') {
        state.queryForm.auditStatus = key
      }
      if (type == 'officalCount') {
        state.queryForm.identity = key
      }
      if (type == 'isExistAuditPassForum') {
        state.queryForm.isExistAuditPassForum = key
      }
      fetchData()
    }

    const selectChangeBySwitch = () => {
      fetchData()
    }
    const handleSelectionChange = (e) => {
      console.log(e)
      state.checkUserList = e
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    const goBack = () => {
      proxy.$router.go(-1)
    }

    const refuse = async () => {
      state['reasonRef'].validate(async (valid) => {
        if (valid) {
          const audienceUid = state.checkUserList.map((v) => v.audienceUid)
          try {
            let parmas = {
              audienceUid: audienceUid,
              auditStatus: 'REFUSE',
              entranceCode: '',
              identity: state.queryForm.identity,
              meetingCode: state.queryForm.meetingCode,
              meetingType: 'EXHIBITION',
              reason: state.form.reason,
              isSendMsg: state.isSendMsg,
            }
            const { data } = await attendAudit(parmas)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          } catch (err) {
            console.log(err)
            proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
          }
          queryData()
          state.dialogFormVisible1 = false
          state.checkUserList = []
        }
      })
    }
    const accept = async () => {
      const audienceUid = state.checkUserList.map((v) => v.audienceUid)
      try {
        let parmas = {
          audienceUid: audienceUid,
          auditStatus: 'ACCEPT',
          entranceCode: '',
          identity: state.queryForm.identity,
          meetingCode: state.queryForm.meetingCode,
          meetingType: 'EXHIBITION',
          reason: state.reason,
          isSendMsg: state.isSendMsg,
        }
        const { data } = await attendAudit(parmas)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      } catch (err) {
        proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
      }
      queryData()
      state.checkUserList = []
    }

    return {
      ...toRefs(state),
      setSelectRows,
      selectChangeBySwitch,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      queryData,
      fetchData,
      selectChange,
      handleSelectionChange,
      handleDate,
      goBack,
      accept,
      refuse,
    }
  },
})
</script>


<style lang="scss" scoped>
.radioBox {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 20px;
    cursor: pointer;
    &.isChecked {
      color: #00c4c9;
    }
  }
}
</style>